<template>
  <div>
    <!-- ... -->
    <apexchart
        ref="ongoingProjectsBarChart"
        type="bar"
        height="400px"
        :options="chartOptions"
        :series="seriesData"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
//   props: ['seriesData'],
  props: {
    chartOptions: Object, // This prop will receive chart options
    seriesData: Array,     // This prop will receive series data
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
    //   chartOptions: {
    //         chart: {
    //         // height: 350,
    //         type: "bar",
    //         events: {
    //             click: function (chart, w, e) {
    //             // console.log(chart, w, e)
    //             },
    //         },
    //         },
    //         colors: [
    //         "#549e39",
    //         "#c0cf3a",
    //         "#4ab5c4",
    //         "#325f22",
    //         "#76801f",
    //         "#28707a",
    //         ],
    //         plotOptions: {
    //         bar: {
    //             dataLabels: {
    //             position: "top", // top, center, bottom
    //             },
    //             distributed: true,
    //         },
    //         },
    //         dataLabels: {
    //             formatter: function (value) {
    //                 return value;
    //             },
    //             // offsetY: -20,
    //             },
    //                 stroke: {
    //                 width: 1,
    //                 colors: ["#fff"],
    //             },
    //         legend: {
    //             show: false,
    //         },
    //         xaxis: {
    //         // title: {
    //         //     text: "MILESTONES",
    //         //     style: { color: "#55c8e9", fontSize: "16px" },
    //         // },
    //         categories: [
    //             "bbbto Start",
    //             "Installation In Progress",
    //             "Installation Completed"
    //             ],
    //         },
    //         yaxis: {
    //         // title: {
    //         //     text: "CAPACITY (KWP)",
    //         //     style: { color: "#55c8e9", fontSize: "16px" },
    //         // },
    //         labels: { style: { colors: ["#FFFFFF"] } },
    //         },
    //         tooltip: {
    //         y: {
    //             formatter: function (val) {
    //             return val + " kWp";
    //             },
    //             title: {
    //             formatter: function (val) {
    //                 return "Capacity:";
    //             },
    //             },
    //         },
    //         },
    //     },
    };
  },
  mounted() {
    // console.log(this.seriesData);
    window.dispatchEvent(new Event('resize'))
  }
  // ...
};
</script>